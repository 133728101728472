/* eslint-disable no-unneeded-ternary */
/*
 * Page Grade
 * Notes: Master Data Grade
 * @author Rizal
 * @date 02/08/2019
 * Modification (please note below)
 * @modify by Gerdi
 * @date 27/11/2019
 * Note Update : Update modal input
 */
/* eslint-disable no-plusplus, no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
/* eslint no-lonely-if: "error" */
/* eslint-disable-next-line no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import {
  Container,
  Col,
  Modal,
  Button,
  Row,
  Card,
  CardBody,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from 'reactstrap';
import './index.css';

import { reduxForm, Field, getFormValues } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/fontawesome-free-solid';
import showNotifications from '../../notificationMessages';
import renderTextInput from '../../../../shared/components/form/TextInput';

import renderSelectField from '../../../../shared/components/form/Select';
import Swal from 'sweetalert2';
import '../../../Tables/DataTable/assets/css/style.css';
import ViewListTable from '../../../../shared/components/table/viewListTable';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// import renderNumberFormat from 'react-number-format';
import utils from '../../../../utils';
import GenerateLeave from './generate.inc';
import { compose } from 'redux';
import { connect } from 'react-redux';

class AttendanceLeavePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    pristine: PropTypes.func.isRequired,

    men_id: PropTypes.func.isRequired,
    fga_id: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEAVE_CODE') },
      { '2': t('LBL.LEAVE_NAME') },
      { '3': t('FRM.FUNCTION') },
    ];

    this.idx_start = 0;
    this.idx_end = 0;
    this.timeout = 0;
    this.state = {
      apiws: '',
      token: '',
      dataWidth: ['5%', '30%', '20%', '20%', '15%'],
      urlData: '',
      statusEdit: false,
      arrGroupShift: [],
      arrShift: [],
      showofficeinput: false,
      arrOffice: [],
      parentLeave: false,
      arrEmpStatus: [],
      arrOrgJobBand: [],
      arrOrgJobGrade: [],
      arrOrgPosition: [],
      employmentStatus: [],
      arrJobLevel: [],
      workingTime: false,
      date: [],
      month: [],
      workflow: [],
      editcond: '',
      addcond: '',
      deletecond: '',
      leavecode: [],
      accumulate: false,
      renew: false,
      minus: false,
      arr: [],
      arrExpos: [],
      incPos: [],
      incJob: [],
      incEms: [],
      balanceRenewal: '',
      carryRenewal: false,
      enableMinus: false,
      limitAmount: false,
      showCanfass: false,
      showSidebar: false,
      employeeTerm: '',
      leaveValidPeriodMonth: false,
      lenForm: 1,
      basicRule: 1,
      isDeducted: false,
      eligibles: [],
      eligibleRules: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.deleteDayOff = this.deleteDayOff.bind(this);
    this.editAttLeave = this.editAttLeave.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getWorkflow = this.getWorkflow.bind(this);
    this.dateAndMonth = this.dateAndMonth.bind(this);
    this.getButton = this.getButton.bind(this);
    this.loadSelectBox = this.loadSelectBox.bind(this);
    this.addNewJobLevel = this.addNewJobLevel.bind(this);
    this.removeNewJobLevel = this.removeNewJobLevel.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getTableData();
      this.getWorkflow();
      this.getLeave();
      this.getButton();
      this.loadSelectBox();
      this.getJobLevel();
    });
    this.setState({ urlData: `${ls.get('ws_ip')}/attleave/getAllitem/` });
    this.dateAndMonth();
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.heads = [
      { '0': t('LBL.NUMBER') },
      { '1': t('LBL.LEAVE_CODE') },
      { '2': t('LBL.LEAVE_NAME') },
      { '3': t('FRM.FUNCTION') },
    ];
  }

  getButton() {
    const men = this.props.men_id;
    const fga = this.props.fga_id;
    const config = {
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      fga_id: fga,
      men_id: men,
    };
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    axios
      .post(
        `${ls.get('ws_ip')}/appfunctionaccess/getAllReference`,
        formBody,
        config,
      )
      .then((res) => {
        if (res.data.status === 'ok') {
          const resmen = res.data.data;
          const array = [];
          if (resmen?.length > 0) {
            resmen.map((obj) => array.push(obj));
          }
          // eslint-disable-next-line array-callback-return
          array.map((dt) => {
            if (dt.fua_read_priv === '1') {
              this.setState({ addcond: dt.fua_add });
              this.setState({ editcond: dt.fua_edit });
              this.setState({ deletecond: dt.fua_delete });
            }
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getTableData() {
    this.child.reload();
  }

  toggle(item) {
    if (item === 'add') {
      const datamaping = {
        balanceRenewal: 1,
        proporsional: 1,
        leave_entitlement: 1,
      };

      this.setState(() => {
        // this.props.destroy();
        this.props.initialize(datamaping);
      });

      this.hideInputOffice();
    } else if (item === 'generate') {
      this.setState({
        showSidebar: !this.state.showSidebar,
      });
      return false;
    }
    this.props.destroy();
    this.setState({
      statusEdit: false,
      modal: !this.state.modal,
      carryRenewal: false,
      enableMinus: false,
      limitAmount: false,
      balanceRenewal: '',
      leaveValidPeriodMonth: false,
      basicRule: '1',
      isDeducted: false,
      lenForm: 1,
    });
  }
  // handlechangeEmployee(val, value, newP, propsName) {

  // }

  handleSubmit(values) {
    const userid = ls.get('user_cred').usr_id;
    const { token, apiws } = this.state;
    const id = values.atl_id;
    const error = true;

    // let ato_id;
    // let radioleavelimit;

    let gender,
      wf,
      leave_code,
      balance_renewal,
      spesific_date,
      spesific_month,
      proporsional,
      leave_entitlement_round,
      employeeTerm,
      ato_id,
      grace,
      is_deducted,
      leave_generate,
      eligibles = [],
      termName;

    let optionEmployee = [
      { value: '1', label: 'All Employee' },
      { value: '2', label: 'All Job Title' },
      { value: '3', label: 'Job Title' },
      { value: '4', label: 'Job Level' },
      { value: '5', label: 'Employee Status' },
    ];

    if (typeof values.workflow === 'undefined' || values.workflow === null) {
      wf = '';
    } else {
      wf =
        typeof values.workflow === 'object'
          ? values.workflow.value
          : values.workflow;
    }
    if (
      typeof values.balanceRenewal === 'undefined' ||
      values.balanceRenewal === null
    ) {
      balance_renewal = '';
    } else {
      balance_renewal =
        typeof values.balanceRenewal === 'object'
          ? values.balanceRenewal.value
          : values.balanceRenewal;
    }

    // when employment rules
    if (values.emerge_rule == '2') {
      this.state.eligibles.map((val, index) => {
        eligibles.push({
          job_id: this.state.eligibleRules[index].value,
          balance: val,
        });
      });
    }

    leave_generate = values.leave_generate;
    // set to unlimited when not deducted
    is_deducted = values.is_deducted ? '1' : '0';
    if (values.is_deducted == '0') {
      balance_renewal = 4;
      leave_generate = 1;
    }

    grace = values.grace;
    if (grace == '' || grace == 'null' || !grace) grace = 0;

    if (
      typeof values.leave_entitlement === 'undefined' ||
      values.leave_entitlement === null
    ) {
      leave_entitlement_round = '';
    } else {
      leave_entitlement_round =
        typeof values.leave_entitlement === 'object'
          ? values.leave_entitlement.value
          : values.leave_entitlement;
    }
    if (typeof values.employee === 'undefined' || values.employee === null) {
      employeeTerm = 1;
    } else {
      employeeTerm =
        typeof values.employee === 'object'
          ? values.employee.value
          : values.employee;
    }
    if (
      typeof values.specificMonth === 'undefined' ||
      values.specificMonth === null ||
      typeof values.specificDate === 'undefined' ||
      values.specificDate === null ||
      typeof values.proporsional === 'undefined' ||
      values.proporsional === null
    ) {
      spesific_date = '';
      spesific_month = '';
      proporsional = '';
    } else {
      spesific_month =
        typeof values.specificMonth === 'object'
          ? values.specificMonth.value
          : values.specificMonth;
      spesific_date =
        typeof values.specificDate === 'object'
          ? values.specificDate.value
          : values.specificDate;
      proporsional =
        typeof values.proporsional === 'object'
          ? values.proporsional.value
          : values.proporsional;
    }

    if (values.Male === true && values.Female === false) {
      gender = 1;
    } else if (values.Female === true && values.Male === false) {
      gender = 2;
    } else if (values.Male === true && values.Female === true) {
      gender = 0;
    } else {
      gender = null;
    }

    if (!utils.isObjectExist(values.leaveCode)) {
      if (values.leaveCode) {
        this.state.leavecode.map((item) => {
          if (item.value == values.leaveCode) {
            leave_code = item.lec_code;
            ato_id = item.value;
          }
        });
      } else {
        leave_code = '';
      }
    } else {
      (ato_id = values.leaveCode.value),
        (leave_code = values.leaveCode.lec_code);
    }

    if (!utils.isObjectExist(values.employee)) {
      optionEmployee.map((item) => {
        if (item.value == values.employee) {
          termName = item.label;
        }
      });
    } else {
      termName = values.employee.label;
    }
    const exclude_pos_id = JSON.stringify(this.state.arrExpos);
    const include_pos_id = JSON.stringify(this.state.incPos);
    const include_job_id = JSON.stringify(this.state.incJob);
    const include_ems_id = JSON.stringify(this.state.incEms);

    let url;

    let data;
    if (id === undefined || id === '') {
      url = `${apiws}/attleave/saveItem`;
      data = {
        ...values,
        workflow: wf,
        gender,
        ato_id,
        leave_code,
        is_deducted,
        leave_generate,
        balance_renewal,
        eligibles: JSON.stringify(eligibles),
        grace,
        spesific_date,
        spesific_month,
        proporsional,
        leave_entitlement_round,
        employeeTerm,
        exclude_pos_id,
        include_pos_id,
        include_job_id,
        include_ems_id,
        termName,
        cuser_id: userid,
      };
    } else {
      url = `${apiws}/attleave/updateItem/${id}`;
      data = {
        ...values,
        workflow: wf,
        gender,
        ato_id,
        leave_code,
        is_deducted,
        leave_generate,
        balance_renewal,
        eligibles: JSON.stringify(eligibles),
        grace,
        spesific_date,
        spesific_month,
        proporsional,
        leave_entitlement_round,
        employeeTerm,
        exclude_pos_id,
        include_pos_id,
        include_job_id,
        include_ems_id,
        termName,
        muser_id: userid,
      };
    }
    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (error) {
      axios
        .post(url, formBody, config)
        .then((response) => {
          if (response.data.status === 'ok') {
            showNotifications('Success', 'Save Success', 'success');
            this.child.reload('addedit');
            this.props.destroy();
            this.setState({ modal: false });
          } else {
            showNotifications('Fail', 'Save Failed', 'danger');
          }
        })
        .catch(() => {
          // console.log(error);
        });
    }
  }

  dateAndMonth() {
    const dt = [];
    const mt = [];
    let i;
    let a;
    let b;
    for (i = 1; i <= 31; i++) {
      if (i <= 9) {
        i = '0'.concat(i);
      }
      dt.push({ value: String(i), label: String(i) });
    }
    this.setState({ date: dt });
    const mnth = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    for (a = 0; a < 12; a++) {
      if (a < 9) {
        b = '0'.concat(a + 1);
      } else {
        b = a;
      }
      mt.push({ value: String(b), label: mnth[a] });
    }
    this.setState({ month: mt });
  }

  editAttLeave(id) {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/attleave/getAllItemById/${id}`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const getResponseData = response.data.data.leave;
          const term_item = response.data.data.item;
          const rules = response.data.data.rules;

          const ex_pos_id = [];
          const in_pos_id = [];
          const in_job_id = [];
          const in_ems = [];
          let name = [];

          if (term_item.length !== 0) {
            if (getResponseData[0].employee_term === '2') {
              term_item.forEach((element) => {
                ex_pos_id.push(element.pos_id);
              });
              name = 'All Job Title';
            } else if (getResponseData[0].employee_term === '3') {
              term_item.forEach((element) => {
                in_pos_id.push(element.pos_id);
              });
              name = 'Job Title';
            } else if (getResponseData[0].employee_term === '4') {
              term_item.forEach((element) => {
                in_job_id.push(element.job_id);
              });
              name = 'Job Level';
            } else if (getResponseData[0].employee_term === '5') {
              term_item.forEach((element) => {
                in_ems.push(element.ems_id);
              });
              name = 'Employee Status';
            }
          } else if (getResponseData[0].employee_term === '1') {
            name = 'All Employee';
          }

          const datamaping = {
            atl_id: getResponseData[0].atl_id,
            workflow: getResponseData[0].wfl_id,
            leaveName: getResponseData[0].leave_name,
            emerge_rule: getResponseData[0].emerge_rule,
            Male:
              getResponseData[0].gender === '0' ||
              getResponseData[0].gender === '1'
                ? true
                : false,
            Female:
              getResponseData[0].gender === '0' ||
              getResponseData[0].gender === '2'
                ? true
                : false,
            available_after:
              getResponseData[0].minimum_working_month === null
                ? ''
                : getResponseData[0].minimum_working_month,
            term_name: name,
            leaveCode: getResponseData[0].ato_id,
            is_deducted: getResponseData[0].is_deducted,
            eligible: getResponseData[0].default_periode_value,
            calculationDay: getResponseData[0].include_day_type,
            leaveDayType: getResponseData[0].leave_taken_type,
            balanceRenewal: +getResponseData[0].leave_entitlement_type,
            leaveValidPeriod: getResponseData[0].can_accumulate,
            leavePeriodMonth: getResponseData[0].acc_until_month,
            grace: getResponseData[0].grace_periode_day,
            leave_generate: getResponseData[0].leave_entitlement_method,
            limit_amount:
              getResponseData[0].is_carryover_limited == 't' ? true : false,
            carry_forward:
              getResponseData[0].can_carryover == 't' ? true : false,
            max_carry: getResponseData[0].max_carry_over,
            leave_entitlement: +getResponseData[0].round_entitlement_type,
            required_attach:
              getResponseData[0].is_attachement_required == 't' ? true : false,
            // enable_minus: getResponseData[0].can_minus == 't' ? true : false,
            max_minus: getResponseData[0].max_minus_value,
            exclude_pos_id: ex_pos_id,
            // include_pos_id: in_pos_id,
            // include_job_id: in_job_id,
            // include_ems_id: in_ems,
            enable_minus: getResponseData[0].can_minus,
            employee: +getResponseData[0].employee_term,
            specificMonth: +getResponseData[0].leave_entitlement_month,
            specificDate: +getResponseData[0].leave_entitlement_date,
            proporsional: +getResponseData[0].proportional,
            except_employee: ex_pos_id,
            pick_employee:
              in_pos_id.length != 0
                ? in_pos_id
                : in_job_id.length != 0
                ? in_job_id
                : in_ems,
          };

          this.setState({
            carryRenewal: datamaping.carry_forward,
            limitAmount: datamaping.limit_amount,
            enableMinus: datamaping.enable_minus,
            balanceRenewal: datamaping.balanceRenewal,
            isDeducted: datamaping.is_deducted == 1,
            basicRule: datamaping.emerge_rule,
          });

          if (rules.length > 0 && getResponseData[0].emerge_rule == '2') {
            this.setState({ lenForm: rules.length });
            // append eligible rules
            const eligibleRules = [];
            const eligibles = [];

            rules.map((val, i) => {
              datamaping[`eligible_rules_${i}`] = val.source_id;
              datamaping[`eligible_balance_${i}`] = val.eligible_days;

              eligibleRules.push({
                value: val.source_id,
                label: val.source_id,
              });
              eligibles.push(val.eligible_days);
            });

            this.setState({ eligibles, eligibleRules });
          }

          if (datamaping.leaveValidPeriod == '1') {
            this.setState({ leaveValidPeriodMonth: true });
          } else if (datamaping.leaveValidPeriod == '2') {
            this.setState({ leaveValidPeriodMonth: false });
          }

          if (datamaping.employee == '1') {
            this.setState({ employeeTerm: 1 });
          } else if (datamaping.employee == '2') {
            this.setState({ employmentStatus: this.state.orgposition });
            this.setState({ employeeTerm: 2 });
          } else if (datamaping.employee == '3') {
            this.setState({ employmentStatus: this.state.orgposition });
            this.setState({ employeeTerm: 3 });
          } else if (datamaping.employee == '4') {
            this.setState({ employmentStatus: this.state.orgjoblevel });
            this.setState({ employeeTerm: 4 });
          } else if (datamaping.employee == '5') {
            this.setState({ employmentStatus: this.state.empstatus });
            this.setState({ employeeTerm: 5 });
          }
          // this.setState({ maxdayLeave: datamaping.maxDayType });
          // this.setState({ repetitionPeriodic: datamaping.radioRecurrence });
          // this.setState({ repetitionJoinDate: datamaping.radioRecurrence });
          this.setState({ statusEdit: true });
          this.setState({ modal: true }, () => {
            this.props.destroy();
            this.props.initialize(datamaping);
          });
        } else {
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  deleteDayOff(id) {
    const { token, apiws } = this.state;
    const userid = ls.get('user_cred').usr_id;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will delete this item !',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/attleave/deleteItem/${id}`,
            `duser_id=${userid}`,
            config,
          )
          .then((response) => {
            if (response.data.status === 'ok') {
              showNotifications('Success', 'Delete Success', 'success');
              setTimeout(() => {
                this.child.reload('delete');
              }, 2000);
            } else {
              showNotifications('Fail', 'Delete Failed', 'danger');
            }
          })
          .catch(() => {
            showNotifications('Fail', 'Delete Failed', 'danger');
          });
      }
    });
  }

  generate() {
    const { apiws, iduser, token } = this.state;

    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Generate leave balance? this may take a few minutes.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff4861',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, generate!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .post(
            `${apiws}/Empleavebalance/getBalanceCounting`,
            `usr_id=${iduser}`,
            config,
          )
          .then((res) => {
            if (res.data.data.length > 0) {
              showNotifications('Success', 'Generated', 'success');
            }
          });
      }
    });
  }

  handlechange(val, value, newP, propsName) {
    if (value == '1' && propsName == 'leaveValidPeriod' && newP != undefined) {
      this.setState({ leaveValidPeriodMonth: true });
    } else if (
      value == '2' &&
      propsName == 'leaveValidPeriod' &&
      newP != undefined
    ) {
      this.setState({ leaveValidPeriodMonth: false });
    }

    if (val.value === '1' && propsName == 'employee') {
      this.setState({ employeeTerm: 1 });
    } else if (val.value === '2' && propsName == 'employee') {
      this.setState({ employmentStatus: this.state.orgposition });
      this.setState({ employeeTerm: 2 });
    } else if (val.value === '3' && propsName == 'employee') {
      this.setState({ employmentStatus: this.state.orgposition });
      this.setState({ employeeTerm: 3 });
    } else if (val.value === '4' && propsName == 'employee') {
      this.setState({ employmentStatus: this.state.orgjoblevel });
      this.setState({ employeeTerm: 4 });
    } else if (val.value === '5' && propsName == 'employee') {
      this.setState({ employmentStatus: this.state.empstatus });
      this.setState({ employeeTerm: 5 });
    } else if (!value && propsName == 'employee') {
      this.setState({ employeeTerm: '' });
    }

    if (propsName == 'carry_forward' && newP != undefined && newP != value) {
      this.setState({
        carryRenewal: !this.state.carryRenewal,
      });
    }
    if (propsName == 'limit_amount' && newP != undefined && newP != value) {
      this.setState({
        limitAmount: !this.state.limitAmount,
      });
    }

    if (propsName === 'except_employee') {
      // EXCEPT EMPLOYEE
      if (this.state.employeeTerm == 2) {
        const newarr = Object.values(val).slice(0, -1);
        this.setState({
          arrExpos: newarr,
        });
      }
    } else if (propsName === 'pick_employee') {
      // PICK EMPLOYEE
      if (this.state.employeeTerm == 3) {
        // INCLUDE POS
        const newarr = Object.values(val).slice(0, -1);
        this.setState({
          incPos: newarr,
        });
      } else if (this.state.employeeTerm == 4) {
        // INCLUDE JOB
        const newarr = Object.values(val).slice(0, -1);
        this.setState({
          incJob: newarr,
        });
      } else if (this.state.employeeTerm == 5) {
        // INCLUDE STATUS
        const newarr = Object.values(val).slice(0, -1);
        this.setState({
          incEms: newarr,
        });
      }
    }
  }

  getJobLevel() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/orgjoblevel/getAll`, '', config)
      .then((res) => {
        const jb = res.data.data;
        const array = [];
        if (jb.length === 0) {
          this.setState({ arrJobLevel: array });
        } else {
          jb.map((obj) =>
            array.push({ value: obj.job_id, label: obj.job_level }),
          );

          this.setState({ arrJobLevel: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getWorkflow() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/workflow/getAll`, '', config)
      .then((response) => {
        const wf = response.data.data;
        const array = [];
        if (wf.length === 0) {
          this.setState({ arrOrgJobBand: array });
        } else {
          wf.map((obj) =>
            array.push({ value: obj.wfl_id, label: obj.workflow }),
          );
          this.setState({ workflow: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getLeave() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/attcode/getleave`, '', config)
      .then((response) => {
        const attcode = response.data.data;
        const array = [];
        attcode.map((obj) =>
          array.push({
            value: obj.ato_id,
            label: obj.ato_value,
            lec_code: obj.ato_code,
          }),
        );
        this.setState({ leavecode: array });
      })
      .catch(() => {
        // console.log(error);
      });
  }

  showInputOffice() {
    this.setState({ showofficeinput: true });
  }

  hideInputOffice() {
    this.setState({ showofficeinput: false });
  }

  addNewJobLevel() {
    this.setState({ lenForm: this.state.lenForm + 1 });
  }

  removeNewJobLevel(index) {
    const { eligibleRules, eligibles, lenForm } = this.state;
    let formValues = this.props.formValues ?? {};
    let fieldLength = 0;

    // counting rules field
    Object.keys(formValues).map((val) => {
      if (new RegExp(/eligible_balance/).exec(val)) fieldLength++;
    });

    // remove existing caching field when field greater than setter values
    for (let i = fieldLength; i > eligibleRules.length; i--) {
      delete formValues[`eligible_rules_${i}`];
      delete formValues[`eligible_balance_${i}`];
    }

    // splice match indexed values
    eligibles.splice(index, 1);
    eligibleRules.splice(index, 1);

    eligibleRules.map((val, index) => {
      formValues[`eligible_rules_${index}`] = val;
      formValues[`eligible_balance_${index}`] = eligibles[index];
    });

    this.props.destroy();
    this.props.initialize(formValues);

    this.setState({
      eligibles,
      eligibleRules,
      lenForm: lenForm - 1,
    });
  }

  loadSelectBox() {
    const { token, apiws } = this.state;
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(`${apiws}/orgjoblevel/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.job_id, label: obj.job_level }),
          );
          this.setState({ orgjoblevel: Arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/rtbemployeementstatus/getAll/`, '', config)
      .then((res) => {
        const dataResponse = res.data.data;
        if (res.data.status === 'ok') {
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.ems_id, label: obj.ems_employeement_status }),
          );
          this.setState({ empstatus: Arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .post(`${apiws}/orgposition/getAll/`, '', config)
      .then((response) => {
        if (response.data.status === 'ok') {
          const dataResponse = response.data.data;
          const Arr = [];
          dataResponse.map((obj) =>
            Arr.push({ value: obj.pos_id, label: obj.position_nm }),
          );
          this.setState({ orgposition: Arr });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  render() {
    const {
      statusEdit,
      urlData,
      arrOffice,
      showofficeinput,
      workflow,
      leavecode,
      carryRenewal,
      enableMinus,
      limitAmount,
      showSidebar,
      date,
      month,
      basicRule,
    } = this.state;
    const { handleSubmit, t, pristine } = this.props;
    let button_action;

    const addBtn = (
      <div>
        <Button
          color="primary"
          className="btn_table_navbar"
          onClick={() => this.toggle('add')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            display: this.state.addcond === '1' ? '' : 'none',
          }}
        >
          {t('FRM.ADD')} {t('LBL.LEAVE_TYPE')}
        </Button>
        <Button
          color="secondary"
          className="btn_table_navbar"
          onClick={() => this.toggle('generate')}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            'margin-right': '5pt',
            // display: this.state.addcond === '1' ? '' : '',
          }}
        >
          Generate
        </Button>

        {/* <Button
          color="secondary"
          className="btn_table_navbar"
          onClick={() => this.generate()}
          style={{
            float: 'right',
            'margin-bottom': '20px',
            'margin-right': '5pt',
            display: this.state.addcond === '1' ? '' : '',
          }}
        >
          Generate
        </Button> */}
        {/* <Button
          color="secondary"
          className="btn_table_navbar"
          onClick={() => this.toggle('generate')}
        >
          Generate
        </Button> */}
      </div>
    );
    const modalStyle = {
      width: '100vw',
      maxWidth: 'none',
      height: '100%',
      margin: '0',
      // maxWidth: '800px',
      // textAlign: 'center',
    };
    if (statusEdit) {
      button_action = (
        <Button
          color="success"
          type="submit"
          form="addleaveform"
          disabled={pristine}
        >
          {' '}
          {t('FRM.EDIT')}
        </Button>
      );
    } else {
      button_action = (
        <Button
          color="primary"
          form="addleaveform"
          type="submit"
          disabled={pristine}
        >
          {t('FRM.SAVE')}
        </Button>
      );
    }
    let officeinput;
    if (showofficeinput) {
      officeinput = (
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t('LBL.OFFICE')} {t('LBL.IF_REGIONAL')}
          </span>
          <div className="form__form-group-field">
            <Field
              name="off_id"
              component={renderSelectField}
              multi="true"
              className="custom-multi"
              type="text"
              options={arrOffice}
            />
          </div>
        </div>
      );
    }

    return (
      <Container>
        <Row>
          <Col md={12} xs={12} lg={12} xl={12}>
            <Card>
              <CardBody style={{ padding: '20px 5px 30px 10px' }}>
                <ViewListTable
                  url={urlData}
                  heads={this.heads}
                  addBtn={addBtn}
                  primaryKey="atl_id"
                  widthTable={this.state.dataWidth}
                  updateFunc={this.editAttLeave}
                  deleteFunc={this.deleteDayOff}
                  displayStyleHead="none"
                  onRef={(ref) => (this.child = ref)}
                  editCond={this.state.editcond}
                  deleteCond={this.state.deletecond}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <GenerateLeave
            showSidebar={showSidebar}
            toggle={() => this.toggle('generate')}
          />
        </Row>

        <Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            id="attendanceSetting"
            className="modal-dialog modal-input modal-fullscreen"
            fullscreen
            style={modalStyle}
          >
            <ModalHeader className="">
              <div className="d-flex w-100">
                <div className="mr-auto">
                  {statusEdit
                    ? t('LBL.EDIT_LEAVE_TYPE')
                    : t('LBL.ADD_LEAVE_TYPE')}
                </div>
                <button className="send__chat" onClick={this.toggle}>
                  <img src="/img/close-icon.svg" width={15} alt="" />
                </button>
              </div>
            </ModalHeader>
            {/* <ModalHeader toggle={this.toggle}>
              {' '}
              {t('FRM.ADD')} {t('LBL.LEAVE_TYPE')}{' '}
            </ModalHeader> */}
            <ModalBody>
              <form
                className="form form--horizontal p-4"
                onSubmit={handleSubmit(this.handleSubmit)}
                name="addleaveform"
                id="addleaveform"
              >
                <Col md={6}>
                  <div className="w-100 group__type__leaves">
                    <h5>{t('LBL.LEAVE_RULES')}</h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_CODE')}{' '}
                      <span className="text-danger"> *</span>
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="leaveCode"
                        component={renderSelectField}
                        // className=""
                        // id="leaveCode"
                        options={leavecode}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_NAME')}{' '}
                      <span className="text-danger"> *</span>
                    </span>
                    <div className="form__form-group-field ">
                      <Field
                        name="leaveName"
                        type="text"
                        component={renderTextInput}
                        className=""
                        placeholder={`${t('LBL.INPUT_LEAVE_NAME')}`}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.WORKFLOW')}{' '}
                      <span className="text-danger"> *</span>
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="workflow"
                        // id="workflow"
                        component={renderSelectField}
                        // className=""
                        options={workflow}
                      />
                    </div>
                  </div>
                  <div className="w-100 group__type__leaves">
                    <h5>{t('LBL.LEAVE_EMERGE')}</h5>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_EMERGE_RULE')}
                      <span className="text-danger"> *</span>
                    </span>
                    <div className="form__form-group-field ">
                      <Field
                        name="emerge_rule"
                        id="emerge_rule"
                        component={renderRadioButtonField}
                        label={t('LBL.LEAVE_EMERGE_RULE_BASIC')}
                        radioValue="1"
                        defaultChecked={basicRule == '1'}
                        onChange={(value) => {
                          this.setState({
                            basicRule: value[0],
                            isDeducted: false,
                            enableMinus: false,
                          });
                        }}
                      />
                      <Field
                        name="emerge_rule"
                        id="emerge_rule"
                        component={renderRadioButtonField}
                        label={t('LBL.LEAVE_EMERGE_RULE_EMPLOYMENT')}
                        radioValue="2"
                        onChange={(value) => {
                          this.setState({
                            basicRule: value[0],
                            isDeducted: true,
                            enableMinus: false,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {basicRule == 1 && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.LEAVE_DEDUCTED')}
                      </span>
                      <div className="form__form-group-field " id="">
                        <Field
                          name="is_deducted"
                          component={renderCheckBoxField}
                          label={t('LBL.YES')}
                          defaultChecked={this.state.isDeducted}
                          onChange={(e, val) => {
                            this.setState({
                              isDeducted: val,
                              enableMinus: false,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {basicRule == 2 && (
                    <>
                      <div
                        className="form__form-group"
                        id="emerge__rules__table"
                      >
                        <span className="form__form-group-label">
                          {t('LBL.LEAVE_RULE_EMPLOYMENT')}
                          <span className="text-danger"> *</span>
                        </span>
                        <div className="form__form-group-field ">
                          <div className="w-100">
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th className="w-50">{t('LBL.JOB_LEVEL')}</th>
                                  <th className="w-50">
                                    {t('LBL.ELIGIBLE_AMOUNT')}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="body__table">
                                {[...Array(this.state.lenForm)].map(
                                  (_value, index) => (
                                    <tr>
                                      <td>
                                        <Field
                                          name={`eligible_rules_${index}`}
                                          component={renderSelectField}
                                          className="mb-0"
                                          options={this.state.arrJobLevel}
                                          onChange={(e, val) => {
                                            const {
                                              eligibleRules,
                                            } = this.state;

                                            eligibleRules.splice(index, 1, val);
                                            this.setState({ eligibleRules });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <div
                                          className="d-flex align-items-start"
                                          id="number__formater"
                                        >
                                          <Field
                                            name={`eligible_balance_${index}`}
                                            type="number"
                                            component={renderTextInput}
                                            placeholder="0"
                                            onChange={(e, val) => {
                                              const { eligibles } = this.state;
                                              eligibles.splice(index, 1, +val);

                                              this.setState({ eligibles });
                                            }}
                                          />
                                          <div className="bg-light group__text_input">
                                            <span>{t('LBL.DAY_TYPE')}</span>
                                          </div>
                                        </div>
                                      </td>

                                      {this.state.lenForm > 1 && (
                                        <td className="pt-3 pl-0">
                                          <a
                                            href="javascript:void(0)"
                                            className="fs-6 remove__job"
                                            onClick={() => {
                                              this.removeNewJobLevel(index);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMinusCircle}
                                              className="text-disabled "
                                            />
                                          </a>
                                        </td>
                                      )}
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>

                      <div className="form__form-group my-3">
                        <div className="form__form-group-field " id="">
                          <a
                            href="javascript:void(0)"
                            id="add__job__level"
                            onClick={() => this.addNewJobLevel()}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="text-primary fs-6"
                            />
                            <span className="ml-2 text-primary">
                              {`${t('FRM.ADD')} ${t('LBL.JOB_LEVEL')}`}
                            </span>
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  {basicRule == '1' && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.ELIGIBLE_AMOUNT')}
                        <span className="text-danger"> *</span>
                      </span>
                      <div className="form__form-group-field ">
                        {this.state.isDeducted && (
                          <Field
                            name="eligible"
                            component={renderTextInput}
                            type="numberDigit"
                            textAlign="left"
                            className=""
                            placeholder={`${t('LBL.INPUT_ELIGIBLE_AMOUNT')}`}
                          />
                        )}

                        {!this.state.isDeducted && (
                          <Field
                            name="eligible_unlimited"
                            component={renderTextInput}
                            type="numberDigit"
                            textAlign="left"
                            className=""
                            placeholder={`Unlimited`}
                            disabled
                          />
                        )}

                        <span className="form__form-group-label ml-3">
                          {t('LBL.DAYS')}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.CALCULATION_DAY')}
                      <span className="text-danger"> *</span>
                    </span>
                    <div className="form__form-group-field ">
                      <Field
                        name="calculationDay"
                        id="calc_work"
                        component={renderRadioButtonField}
                        label={t('LBL.WORKING_DAY')}
                        radioValue="1"
                        defaultChecked
                      />
                      <Field
                        name="calculationDay"
                        id="calc_work"
                        component={renderRadioButtonField}
                        label={t('LBL.CALENDER_DAY')}
                        radioValue="2"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_DAY_TYPE')}{' '}
                      <span className="text-danger">*</span>
                    </span>
                    <div
                      className="form__form-group-field day__type__leave"
                      id=""
                    >
                      <Field
                        name="leaveDayType"
                        id="full_day"
                        component={renderRadioButtonField}
                        label={t('LBL.FULL_DAY')}
                        radioValue="1"
                        defaultChecked
                      />
                      <Field
                        name="leaveDayType"
                        id="half_day"
                        component={renderRadioButtonField}
                        label={t('LBL.HALF_DAY')}
                        radioValue="2"
                      />
                    </div>
                  </div>

                  {this.state.isDeducted && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.BALANCE_RENEWAL')}{' '}
                        <span className="text-danger">*</span>
                      </span>
                      <div className="form__form-group-field ">
                        <Field
                          name="balanceRenewal"
                          component={renderSelectField}
                          className=""
                          options={[
                            { value: '1', label: `${t('LBL.ANNIVERSARY')}` },
                            { value: '2', label: `${t('LBL.ANNUALLY')}` },
                            { value: '3', label: `${t('LBL.MONTHLY')}` },
                          ]}
                          onChange={(e) => {
                            // console.log(e.value)
                            this.setState({ balanceRenewal: e.value });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {/* Balance Renewal  */}
                  {this.state.balanceRenewal == '2' && (
                    <div className="form__form-group group__label__setting">
                      <span className="form__form-group-label">
                        {t('LBL.LEAVE_ENTITLE_PERIOD')}{' '}
                        <span className="text-danger">*</span>
                      </span>
                      <div className="form__form-group-field align-items-center">
                        <span className="">{t('LBL.SPESIFIC_DATE')}</span>
                        <Field
                          name="specificMonth"
                          component={renderSelectField}
                          className=""
                          options={month}
                        />
                        <Field
                          name="specificDate"
                          component={renderSelectField}
                          className=""
                          options={date}
                        />
                      </div>

                      <div className="form__form-group-field align-items-center">
                        <Row style={{ width: '100%' }}>
                          <Col md={8}>
                            <span
                              className="form__form-group-label"
                              style={{ width: '100%' }}
                            >
                              {`Employee who joined before date will be rounded to 1 month`}
                            </span>
                          </Col>
                          <Col md={4}>
                            <Field
                              name="proporsional"
                              component={renderSelectField}
                              options={date}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}

                  {this.state.balanceRenewal == '3' && (
                    <div className="form__form-group-field align-items-center">
                      <Row style={{ width: '100%' }}>
                        <Col md={8}>
                          <span
                            className="form__form-group-label"
                            style={{ width: '100%' }}
                          >
                            {`Employee who joined before date will be rounded to 1 month`}
                          </span>
                        </Col>
                        <Col md={4}>
                          <Field
                            name="proporsional"
                            component={renderSelectField}
                            options={date}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div className="w-100 group__type__leaves ">
                    <h5>{t('LBL.LEAVE_EXPIRED')}</h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.LEAVE_VALID_END')}{' '}
                      <span className="text-danger">*</span>
                    </span>
                    <div
                      className="form__form-group-field day__type__leave"
                      id=""
                    >
                      <Field
                        name="leaveValidPeriod"
                        id="leavePeriodMonth"
                        component={renderRadioButtonField}
                        label={t('LBL.MONTH')}
                        radioValue="1"
                        onChange={this.handlechange}
                      />
                      <Field
                        name="leaveValidPeriod"
                        id="leavePeriodRemaining"
                        component={renderRadioButtonField}
                        label={t('LBL.REMAINING_LEAVE')}
                        radioValue="2"
                        defaultChecked
                        onChange={this.handlechange}
                      />
                    </div>
                  </div>
                  {this.state.leaveValidPeriodMonth == true ? (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.LEAVE_PERIOD_MONTH')}{' '}
                        <span className="text-danger"> *</span>
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="leavePeriodMonth"
                          component={renderTextInput}
                          type="numberDigit"
                          textAlign="left"
                          className=""
                          placeholder={`${t('LBL.INPUT_VALID_END_PERIOD')}`}
                        />
                        <span className="form__form-group-label ml-3">
                          {t('LBL.MONTH')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.REQ_GRACE_PERIOD')}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="grace"
                        component={renderTextInput}
                        type="numberDigit"
                        textAlign="left"
                        className=""
                        placeholder={`${t('LBL.INPUT_GRACE_PERIOD')}`}
                      />
                      <span className="form__form-group-label ml-3">
                        {t('LBL.DAYS')}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="w-100 group__type__leaves">
                    <h5>{t('LBL.LEAVE_ENTITLEMENT')}</h5>
                  </div>

                  {(this.state.isDeducted || basicRule == '2') && (
                    <>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.LEAVE_ENTITLEMENT_AVAIL')}
                        </span>
                        <div className="form__form-group-field" id="">
                          <Field
                            name="leave_generate"
                            id="full_generate"
                            component={renderRadioButtonField}
                            label={t('LBL.FULL')}
                            radioValue="1"
                            defaultChecked
                          />
                          {this.state.balanceRenewal != 3 &&
                            (this.state.balanceRenewal != 4 ? (
                              <Field
                                name="leave_generate"
                                id="half_generate"
                                component={renderRadioButtonField}
                                label={t('LBL.INCREMENTAL_MONTHLY')}
                                radioValue="2"
                              />
                            ) : (
                              ''
                            ))}
                        </div>
                      </div>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.CARRY_FORWARD_BALANCE')}
                        </span>
                        <div className="form__form-group-field" id="">
                          <Field
                            name="carry_forward"
                            // id="carry_forward"
                            component={renderCheckBoxField}
                            label={t('LBL.YES')}
                            // checked={carryRenewal == true}
                            onChange={this.handlechange}
                          />
                        </div>
                      </div>

                      {carryRenewal ? (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.LIMIT_CARRY_FORWARD')}
                          </span>
                          <div className="form__form-group-field" id="">
                            <Field
                              name="limit_amount"
                              // id="limit_amount"
                              value="true"
                              component={renderCheckBoxField}
                              label={t('LBL.YES')}
                              onChange={this.handlechange}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {limitAmount && carryRenewal && (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.MAX_CARRY_FORWARD')}
                            <span className="text-danger"> *</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="max_carry"
                              component={renderTextInput}
                              type="numberDigit"
                              textAlign="left"
                              className=""
                              placeholder={`${t(
                                'LBL.INPUT_MAX_CARRY_OVER_AMOUNT',
                              )}`}
                            />
                            <span className="form__form-group-label ml-3">
                              {t('LBL.DAYS')}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.ENABLE_MIN')}
                        </span>
                        <div className="form__form-group-field " id="">
                          <Field
                            name="enable_minus"
                            // id="enable_minus"
                            component={renderCheckBoxField}
                            label={t('LBL.YES')}
                            // checked={enableMinus == true}
                            onChange={() =>
                              this.setState({
                                enableMinus: enableMinus ? false : true,
                              })
                            }
                          />
                        </div>
                      </div>
                      {!enableMinus && (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t('LBL.MAX_MINUS')}
                            <span className="text-danger"> *</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="max_minus"
                              component={renderTextInput}
                              type="numberDigit"
                              textAlign="left"
                              className=""
                              placeholder={`${t('LBL.INPUT_MAX_MINUS')}`}
                            />
                            <span className="form__form-group-label ml-3">
                              {t('LBL.DAYS')}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {t('LBL.LEAVE_ENT_ROUND')}
                        </span>
                        <div className="form__form-group-field ">
                          <Field
                            name="leave_entitlement"
                            component={renderSelectField}
                            className=""
                            options={[
                              { value: '1', label: 'No Rounding' },
                              { value: '2', label: 'Normal Rounding' },
                              { value: '3', label: 'Round Up' },
                              { value: '4', label: 'Round Down' },
                            ]}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.REQ_ATTACH')}
                    </span>
                    <div className="form__form-group-field " id="">
                      <Field
                        name="required_attach"
                        id="required_attach"
                        component={renderCheckBoxField}
                        label={t('LBL.YES')}
                      />
                    </div>
                  </div>

                  <div className="w-100 group__type__leaves">
                    <h5>{t('LBL.EMP_TERM')}</h5>
                  </div>
                  <div className="form__form-group checkbox__group">
                    <span className="form__form-group-label">
                      {t('LBL.SEX')}
                    </span>
                    <div className="form__form-group-field " id="">
                      <Field
                        name="Male"
                        component={renderCheckBoxField}
                        label={t('LBL.MAN')}
                      />
                      <Field
                        name="Female"
                        component={renderCheckBoxField}
                        label={t('LBL.WOMAN')}
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.AVAIL_AFTER')}
                    </span>
                    <div className="form__form-group-field ">
                      <Field
                        name="available_after"
                        component={renderTextInput}
                        type="numberDigit"
                        textAlign="left"
                        className=""
                        placeholder={`${t('LBL.INPUT_AVAIL_AFTER')}`}
                      />
                      <span className="ml-3">
                        {t('LBL.MONTH_FROM_JOIN_DATE')}
                      </span>
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t('LBL.EMPLOYEE')}
                    </span>
                    <div className="form__form-group-field ">
                      <Field
                        name="employee"
                        component={renderSelectField}
                        className=""
                        options={[
                          { value: '1', label: 'All Employee' },
                          { value: '2', label: 'All Job Title' },
                          { value: '3', label: 'Job Title' },
                          { value: '4', label: 'Job Level' },
                          { value: '5', label: 'Employee Status' },
                        ]}
                        onChange={this.handlechange}
                      />
                    </div>
                  </div>

                  {this.state.employeeTerm == 1 ||
                  this.state.employeeTerm == '' ? (
                    ''
                  ) : this.state.employeeTerm > 2 ? (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.PICK_EMPLOYEE')}
                      </span>
                      <div className="form__form-group-field ">
                        <Field
                          name="pick_employee"
                          component={renderSelectField}
                          className=""
                          options={this.state.employmentStatus}
                          multi="true"
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t('LBL.EXCEPT_EMPLOYEE')}
                      </span>
                      <div className="form__form-group-field ">
                        <Field
                          name="except_employee"
                          component={renderSelectField}
                          className=""
                          options={this.state.employmentStatus}
                          multi="true"
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                {t('FRM.CANCEL')}
              </Button>
              {button_action}
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.leaveName) {
    errors.leaveName = 'Leave name field shouldn’t be empty';
  }
  if (!values.leaveCode) {
    errors.leaveCode = 'Leave code field shouldn’t be empty';
  }
  if (!values.workflow) {
    errors.workflow = 'Workflow field shouldn’t be empty';
  }
  if (!values.eligible && values.is_deducted) {
    errors.eligible = 'Eligible Leave Amount field shouldn’t be empty';
  }
  if (!values.calculationDay) {
    errors.calculationDay = 'Calculation Day Count field shouldn’t be empty';
  }
  if (!values.leaveDayType) {
    errors.leaveDayType = 'Leave Day Type field shouldn’t be empty';
  }
  if (!values.leaveDayTypeExp) {
    errors.leaveDayTypeExp = 'Leave Day Type Expired field shouldn’t be empty';
  }
  if (!values.balanceRenewal) {
    errors.balanceRenewal = 'Balance Renewal By field shouldn’t be empty';
  }
  if (!values.specificMonth || !values.specificDate) {
    errors.specificDate = 'Spesific Date field shouldn’t be empty';
  }
  if (!values.proporsional && values.specificMonth && values.specificDate) {
    errors.proporsional = 'Proportional field shouldn’t be empty';
  } else if (!values.proporsional && values.balanceRenewal?.value == '3') {
    errors.proporsional = 'Proportional field shouldn’t be empty';
  }
  if (
    values?.max_carry?.toString()?.trim() == '' ||
    values.max_carry == undefined
  ) {
    errors.max_carry = 'Maximum Balance field shouldn’t be empty';
  }
  if (
    values?.max_minus?.toString()?.trim() == '' ||
    values.max_minus == undefined
  ) {
    errors.max_minus = 'Maximum Balance field shouldn’t be empty';
  }
  if (!values.leavePeriodMonth) {
    errors.leavePeriodMonth = 'Leave Period month shouldn’t be empty';
  }
  return errors;
};

export default compose(
  connect((state) => ({
    formValues: getFormValues('leaveforms')(state),
  })),
  reduxForm({
    form: 'leaveforms',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
  }),
)(translate('global')(AttendanceLeavePage));
