/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-did-mount-set-state, object-shorthand, react/prop-types */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

const renderDatePickerField = React.memo((props) => {
  const [dateValue, setDateValue] = useState('');

  useEffect(() => {
    if (props.input.value) {
      if (props.isClearable && props?.value == '') {
        setDateValue(null);
      } else {
        setDateValue(props.input.value);
      }
    }
  }, [props.input.value]);

  useEffect(() => {
    if (props?.value) {
      setDateValue(moment(props.value));
    } else {
      setDateValue(null);
    }
  }, [props.value]);

  return (
    <div className={`date-picker ${props?.className ?? ''}`}>
      <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <DatePicker
          ref={(ref) => {
            if (props.input.ref) props.input?.ref(ref);
          }}
          className="form__form-group-datepicker"
          selected={dateValue}
          onChange={(val) => {
            setDateValue(val);
            props.input.onChange(val);
          }}
          // name={props.input.name}
          placeholderText={props.input.placeholderText || props.placeholderText}
          dateFormat={props.dateFormat || 'DD/MM/YYYY'}
          disabled={props.disabled || false}
          showMonthDropdown
          showYearDropdown
          onBlur={() =>
            props.input?.onBlur(props?.input?.value || props?.value)
          }
        />
        {props.meta.touched && props.meta.error && (
          <span className="form__form-group-error">{props.meta.error}</span>
        )}
        {(props.disableTouched || !props.meta.pristine) && props.meta.error && (
          <span className="form__form-group-error">{props.meta.error}</span>
        )}
      </div>
    </div>
  );
});

// renderDatePickerField.propTypes = {
//   input: PropTypes.shape({
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     name: PropTypes.string,
//     placeholderText: PropTypes.string,
//     disabled: PropTypes.bool,
//   }).isRequired,
//   meta: PropTypes.shape({
//     touched: PropTypes.bool,
//     error: PropTypes.string,
//   }),
// };

// renderDatePickerField.defaultProps = {
//   meta: null,
// };

export default renderDatePickerField;
