/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faSync,
  faTimes,
  faClock,
  faFileImage,
  faFilePdf,
} from '@fortawesome/fontawesome-free-solid';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ls from 'local-storage';
import ViewListTable from '../../../../../shared/components/table/viewListTable';
import axios from 'axios';
import showNotifications from '../../../notificationMessages';
import Swal from 'sweetalert2';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderIntervalDatePickerField from '../../../../../shared/components/form/IntervalDatePicker';
import { translate } from 'react-i18next';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import renderTextInput from '../../../../../shared/components/form/TextInput';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import renderTimePickerIntervalField from '../../../../../shared/components/form/TimePickerInterval';
import Config from '../../../../../shared/components/config/config';
import renderNumberFormat from '../../../../../shared/components/form/NumberFormat';

class submissionForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
    wfo_id: PropTypes.string.isRequired,
    emp_id: PropTypes.string.isRequired,
    initialize: PropTypes.string.isRequired,
    disabledField: PropTypes.bool.isRequired,
    getValue: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    const { t } = props;
    this.heads = [
      { 0: t('LBL.NUMBER') },
      { 1: t('LBL.LEAVE_TYPE') },
      { 2: t('LBL.START_DATE') },
      { 3: t('LBL.END_DATE') },
      { 4: t('LBL.TOTAL') },
      { 6: t('FRM.FUNCTION') },
    ];

    this.state = {
      // rows: this.createRows(3),
      // pageOfItems: [],
      token: '',
      apiws: '',
      urlData: '',
      userId: '',
      empId: '',
      dataWidth: ['5%', '15%', '20%', '20%', '15%', '15%', '10%'],
      emp_id: '',
      redirect: false,
      addcond: '',
      editcond: '',
      deletecond: '',
      displayLoad: 'none',
      displaySave: '',
      disabled: false,
      arrworkflow: [],
      arrreason: [],
      arrType: [],
      arrEmp: [],
      attachment: [],
      trigger: false,
      claim_value: 0,
      typeDoc: '',
    };
    this.toggle = this.toggle.bind(this);
    this.getClaimReq = this.getClaimReq.bind(this);
    this.getClaimType = this.getClaimType.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.linkBack = this.linkBack.bind(this);
    this.checkAttachment = this.checkAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.viewAttachment = this.viewAttachment.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  componentDidMount = () => {
    this.setState({ token: ls.get('token') });
    this.setState({ userId: ls.get('user_cred').usr_id });
    this.setState({ empId: ls.get('user_cred').emp_id });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getClaimType();
      // this.getButton();
    });
    if (typeof this.props.wfo_id !== 'undefined') {
      this.getClaimReq();
      this.setState({ disabled: this.props.disabledField });
      this.setState({ displaySave: 'none' });
    }
  };

  componentWillReceiveProps(newProps) {
    const { t } = newProps;
    this.setState({ token: ls.get('token') });
    this.setState({ apiws: ls.get('ws_ip') }, () => {
      this.getClaimType();
      this.getEmployee();
      // this.getButton();
    });
  }

  getEmployee() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    axios
      .post(`${ls.get('ws_ip')}/employee/getAllAsc`, '', config)
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.emp_id, label: obj.emp_name }),
          );
          this.setState({ arrEmp: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getClaimReq() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const wfo = this.props.wfo_id;
    axios
      .get(`${ls.get('ws_ip')}/Appreimbursement/getClaimByWfo/${wfo}`, config)
      .then((resemp) => {
        const dataResponse = resemp.data.data;
        const fileResponse = resemp.data.file;
        const datamapp = {
          ect_id: dataResponse[0].ect_id,
          claim_date: moment(dataResponse[0].claim_date),
          claim_value: dataResponse[0].claim_value,
          ecr_reason: dataResponse[0].ecr_reason,
        };
        this.setState({ claim_value: dataResponse[0].claim_value });
        const files = [];
        fileResponse.map((f) => {
          axios
            .get(`${ls.get('ws_ip')}/public/uploads/${f.file_path}`, {
              headers: { Authorization: `Bearer ${ls.get('token')}` },
              responseType: 'blob',
            })
            .then((resf) => {
              // Gunakan regex untuk mendapatkan ekstensi file
              const match = f.file_path.match(/\.([a-zA-Z0-9]+)$/);
              let attac;

              if (match) {
                const extension = match[1]; // Dapatkan ekstensi file
                // Cek apakah ekstensi file adalah "pdf" atau "PDF"
                if (/^pdf$/i.test(extension)) {
                  attac = new File([resf.data], f.file_name, {
                    type: 'application/pdf',
                    lastModified: Date.now(),
                  });
                } else {
                  attac = new File([resf.data], f.file_name);
                }
                files.push(attac);
              }
            });
        });
        this.setState({ attachment: files });
        this.props.initialize(datamapp);
      })
      .catch(() => {});
  }

  getWorkflow() {
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    const empid = this.state.emp_id;
    axios
      .post(`${ls.get('ws_ip')}/workflow/getAllItem/`, '', config)
      .then((resemp) => {
        const emp = resemp.data.data;
        const array = [];
        if (emp.length === 0) {
          this.setState({ arrEmp: array });
        } else {
          emp.map((obj) =>
            array.push({ value: obj.wfl_id, label: obj.workflow }),
          );
          this.setState({ arrworkflow: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  getClaimType() {
    const { apiws } = this.state;
    const config = {
      headers: { Authorization: `Bearer ${ls.get('token')}` },
    };
    let empIds;
    if (this.props.emp_id !== undefined) {
      empIds = this.props.emp_id;
    } else {
      empIds = this.state.empId;
    }
    axios
      .post(
        `${apiws}/appreimbursement/getAllowedClaim/`,
        `emp_id=${empIds}`,
        config,
      )
      .then((res) => {
        const claim = res.data.data;
        const array = [];
        if (claim.length === 0) {
          this.setState({ arrClaimType: array });
        } else {
          claim.map((obj) =>
            array.push({ value: obj.ect_id, label: obj.claim_name }),
          );
          this.setState({ arrClaimType: array });
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  linkBack() {
    this.setState({ redirect: true });
  }

  checkAttachment(e) {
    const file = e.target.files[0];
    const allowedType = [
      'application/pdf',
      'image/gif',
      'image/jpeg',
      'image/png',
    ];
    const allowedSize = 1000; // in kilobytes
    if (!allowedType.includes(file.type)) {
      Swal.fire({
        title: 'Warning',
        text: 'File type not allowed!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else if (Math.ceil(file.size / 1000) > allowedSize) {
      Swal.fire({
        title: 'Warning',
        text: 'Maximum file size exceeded!',
        type: 'warning',
        showCloseButton: true,
        closeButtonColor: '#3085d6',
        reverseButtons: true,
      });
    } else {
      this.props.getValue(
        '',
        [...this.state.attachment, file],
        '',
        'attachment',
      );
      this.setState({ attachment: [...this.state.attachment, file] });
    }
  }

  removeAttachment(pos) {
    const list = this.state.attachment;
    list.splice(pos, 1);
    setTimeout(() => {
      this.props.getValue(this.props.value, list, '', 'attachment');
    }, 200);
    this.setState({ attachment: list, trigger: !this.state.trigger });
  }

  viewAttachment(pos) {
    const list = this.state.attachment;

    let base64String;
    const reader = new FileReader();
    reader.readAsDataURL(list[pos]);
    reader.onloadend = () => {
      base64String = reader.result;
      this.setState({
        typeDoc: list[pos].name.match(/\.([a-zA-Z0-9]+)$/)[1],
        modalHeader: list[pos].name,
        modalContent: new RegExp(/.pdf/i).exec(list[pos].type)
          ? base64String.substr(base64String.indexOf(',') + 1)
          : list[pos]?.path === 'string'
          ? list[pos].path
          : URL.createObjectURL(list[pos]),
      });
    };

    this.toggle();
  }

  downloadAttachment(pos) {
    const list = this.state.attachment;
    const objectURL =
      typeof list[pos].path === 'string'
        ? list[pos].path
        : URL.createObjectURL(list[pos]);
    const a = document.createElement('a');
    a.href = objectURL;
    a.target = '_blank';
    a.download = list[pos].name;
    a.click();
  }

  handleSubmit(values) {
    this.setState({ displayLoad: '', displaySave: 'none' });
    const { apiws, token } = this.state;
    const userid = ls.get('user_cred').usr_id;
    // eslint-disable-next-line prefer-destructuring
    const emp_id = ls.get('user_cred').emp_id;
    const overtime_date = moment(values.overtime_date).format('YYYY-MM-DD');
    // const remarks = typeof values.remarks === 'undefined' ? '' : values.remarks;
    const wfl_id =
      typeof values.oct_id.wfl_id === 'undefined' ||
      values.oct_id.wfl_id === null
        ? ''
        : values.oct_id.wfl_id;
    const oct_id =
      typeof values.oct_id === 'undefined' || values.oct_id === null
        ? ''
        : values.oct_id.value || values.oct_id;
    const url = `${apiws}/workflow/generateattendancecase`;
    const data = {
      ...values,
      wfl_id,
      emp_id,
      oct_id,
      //   remarks,
      overtime_date,
      cuser_id: userid,
    };

    const formBody = Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    axios
      .post(url, formBody, config)
      .then((response) => {
        if (response.data.status === 'ok') {
          showNotifications(
            'Success',
            'Leave request has submitted',
            'success',
          );
          this.setState({
            displayLoad: 'none',
            displaySave: '',
            redirect: true,
          });
          setTimeout(() => {
            this.props.destroy();
          }, 200);
        } else {
          this.setState({ displayLoad: 'none', displaySave: '' });
          showNotifications('Fail', 'Save Failed', 'danger');
        }
      })
      .catch(() => {
        // console.log(error);
      });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const {
      disabled,
      displayLoad,
      displaySave,
      arrworkflow,
      arrreason,
      arrEmp,
      arrClaimType,
      attachment,
    } = this.state;
    const addBtn = (
      <Button
        color="primary"
        className="btn_table_navbar"
        onClick={() => this.toggle()}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          display: this.state.addcond === '1' ? '' : 'none',
        }}
      >
        {t('FRM.ADD')} {t('LBL.CLAIM_REQUEST')}
      </Button>
    );
    const backBtn = (
      <Button
        color="default"
        className="btn btn-secondary btn-sm btn_table_navbar"
        onClick={this.linkBack}
        style={{
          float: 'right',
          'margin-bottom': '20px',
          'margin-right': '10px',
        }}
      >
        {t('FRM.BACK')}
      </Button>
    );
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Row>
                  {/* {this.state.disabled === false && (
                    <Col md={8}>
                      <h3 className="page-title">{t('LBL.SUBMISSION_OVERTIME')}</h3>
                    </Col>
                  )} */}
                  <Col md={12} xs={12} lg={12} xl={12}>
                    <form
                      className="form form--horizontal"
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="addattmonitoring"
                      id="addattmonitoring"
                    >
                      <Field
                        name="adq_id"
                        component={renderTextInput}
                        type="hidden"
                      />
                      <Container>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_TYPE')}
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name="ect_id"
                                  component={renderSelectField}
                                  options={arrClaimType}
                                  onChange={this.props.getValue}
                                  disabled={disabled}
                                  props={{
                                    disableTouched: this.props.disabled,
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="8" lg="8" xl="8" sm="8">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_DATE')}
                              </span>
                              <div className="form__form-group-field">
                                <Row className="w-100">
                                  <Col md={8} sm={12}>
                                    <div className="form__form-group-field form-group-ml-pl-0 w-100">
                                      <Field
                                        name="claim_date"
                                        component={renderDatePickerField}
                                        onChange={this.props.getValue}
                                        disabled={disabled}
                                        props={{
                                          disableTouched: this.props.disabled,
                                        }}
                                      />
                                      <div className="form__form-group-icon">
                                        <CalendarBlankIcon />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" lg="6" xl="6" sm="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.CLAIM_AMOUNT')}
                              </span>
                              <div className="form__form-group-field number-form">
                                <Field
                                  name="claim_value"
                                  component={renderNumberFormat}
                                  props={{
                                    disableTouched: this.props.disabled,
                                  }}
                                  onChange={(val) => {
                                    this.setState({
                                      claim_value: val.floatValue,
                                    });
                                    if (this.props.emp_id === undefined) {
                                      setTimeout(() => {
                                        this.props.getValue(
                                          val.floatValue,
                                          this.state.claim_value,
                                          '',
                                          'claim_value',
                                        );
                                      }, 200);
                                    }
                                  }}
                                  other={{
                                    defaultValue: this.state.claim_value,
                                    value: this.state.claim_value,
                                    thousandSeparator: '.',
                                    decimalSeparator: ',',
                                    prefix: 'Rp',
                                    maxLength: '19',
                                    disabled,
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.DESCRIPTION')}
                              </span>
                              <div className="form__form-group-field">
                                <Col md={12} xs={12} style={{ padding: '0' }}>
                                  <Field
                                    name="ecr_reason"
                                    component={renderTextInput}
                                    type="textarea"
                                    onChange={this.props.getValue}
                                    disabled={disabled}
                                    props={{
                                      disableTouched: this.props.disabled,
                                    }}
                                    maxLength="350"
                                  />
                                </Col>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" lg="12" xl="12" sm="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                {t('LBL.UPLOAD_FILE')}
                              </span>
                              <div
                                className="form__form-group-file"
                                style={{ paddingLeft: '20px' }}
                              >
                                {this.props.emp_id === undefined && (
                                  <label htmlFor="attachment">
                                    Choose the file
                                  </label>
                                )}
                                {this.props.emp_id === undefined && (
                                  <div>
                                    <input
                                      type="file"
                                      name="attachment"
                                      id="attachment"
                                      disabled={this.props.disabled}
                                      onChange={(e) => {
                                        this.checkAttachment(e);
                                        setTimeout(() => {
                                          this.props.getValue(
                                            this.props.value,
                                            this.state.attachment,
                                            '',
                                            'attachment',
                                          );
                                        }, 200);
                                      }}
                                    />
                                    <small>File type: png,jpg,jpeg,pdf</small>
                                    <br />
                                    <small>File Size Max: 1Mb</small>
                                  </div>
                                )}
                                <div
                                  style={{
                                    border: '1px solid #ddd',
                                    borderRadius: '2pt',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {attachment.map((file, pos) => (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        borderTop: pos > 0 && '1px solid #ddd',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          file.type === 'application/pdf' ||
                                          file.name.split('.')[
                                            file.name.split('.').length - 1
                                          ] === 'pdf'
                                            ? faFilePdf
                                            : faFileImage
                                        }
                                        style={{
                                          marginTop: '5px',
                                          marginLeft: '3px',
                                        }}
                                      />
                                      <div className="mt-1">{file.name}</div>
                                      <div>
                                        {/* {file.type !== 'application/pdf' &&
                                          file.name.split('.')[
                                            file.name.split('.').length - 1
                                          ] !== 'pdf' && ( */}
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.viewAttachment(pos)
                                          }
                                          className="btn btn-sm btn-outline-secondary btn-data-table mx-1"
                                        >
                                          {t('LBL.VIEW')}
                                        </button>
                                        {/* )} */}
                                        <button
                                          type="button"
                                          onClick={() =>
                                            this.downloadAttachment(pos)
                                          }
                                          className="btn btn-sm btn-outline-success btn-data-table mx-1"
                                        >
                                          {t('FRM.DOWNLOAD')}
                                        </button>
                                        {this.props.emp_id === undefined && (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              this.removeAttachment(pos)
                                            }
                                            className="btn btn-sm btn-outline-danger btn-data-table mx-1"
                                          >
                                            <i className="fas fa-times" />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog modal-input"
          style={{ maxWidth: '800px', marginTop: '30px', textAlign: 'center' }}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.modalHeader}
          </ModalHeader>
          <ModalBody>
            {this.state.typeDoc?.toLowerCase() === 'pdf' ? (
              <iframe
                src={`data:application/pdf;base64,${this.state.modalContent}`}
                title={this.state.modalHeader}
                width={770}
                height={700}
              />
            ) : (
              <img src={this.state.modalContent} alt={this.state.modalHeader} />
            )}
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.attachment) {
    errors.attachment = 'Attachment file shouldn’t be empty';
  }
  if (!values.ect_id) {
    errors.ect_id = 'Claim name field shouldn’t be empty';
  }
  if (!values.claim_date) {
    errors.claim_date = 'Claim date field shouldn’t be empty';
  }
  if (!values.claim_value) {
    errors.claim_value = 'Claim amount field shouldn’t be empty';
  }
  return errors;
};

export default reduxForm({
  form: 'approval', // a unique identifier for this form
  validate,
})(translate('global')(submissionForm));
