import React from 'react';
import { Card, Col, Row } from 'reactstrap';

const Loading = ({
  isLoading = false,
  width = '100vw',
  title = null,
  subtitle = null,
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <div
            className={`load ${!isLoading ? 'loaded' : ''}`}
            style={{ textAlign: 'center', width }}
          >
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path
                  fill="#1974EF"
                  d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                />
              </svg>
              {title && <h4 className="mt-5">{title}</h4>}
              {subtitle && <span>{subtitle}</span>}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Loading;
